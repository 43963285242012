export const getUserProfile = async ({
  accessToken,
}: {
  accessToken: string
}) => {
  if (!accessToken) {
    console.error("No access token found")
    throw new Error("No access token found")
  }

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_VOXIES_API}/public/v1/users/me`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  if (!response.ok) {
    throw new Error("Failed to get user profile")
  }

  return await response.json()
}
